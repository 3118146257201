<!--
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-04-16 21:58:15
 * @Description: 优惠券使用详情
 
-->
<template>
  <div class="padding24 color_white">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div class="top">
        <a-form layout="inline">
          <a-form-item label="订单状态">
            <a-select
              v-model="status"
              :getPopupContainer="
                (triggerNode) => triggerNode.parentNode || document.body
              "
              style="width: 120px"
              @change="orderStatusChange"
            >
              <a-select-option v-for="item in orderStatusArr" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="用户手机号">
            <a-input
              v-model="userName"
              placeholder="请输入用户手机号"
              @pressEnter="searchClick"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-button
              icon="search"
              type="primary"
              @click="searchClick"
              :disabled="isDisableSearch"
            >
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
      >
        <span slot="status" slot-scope="status, record">
          {{ orderStatusMap[status] }}
        </span>
      </a-table>
      <MyPagination
        :count="count"
        :pageNo="pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="!tableLoading"
      ></MyPagination>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import {
  CouponUsedDetailApi,
  UpdateorderStatusApi,
} from "@/request/api/marketingCenter";
import MyPagination from "@/components/pagination/MyPagination";
import { message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";

export default {
  components: {
    MyPagination,
  },
  created() {
    this.couNo = this.$route.params.id;
    this.getCouponUsedListFn();
    this.handleOrderStatusArr();
  },
  data() {
    return {
      spinning: false, // 全局loading
      tip: "",
      pageNo: 1,
      pageSize: 20,
      count: 0,
      tableLoading: true,
      tableData: [],
      columns: [
        {
          title: "订单号",
          fixed: "left",
          dataIndex: "orderNo",
          key: "orderNo",
          width: 200,
          // scopedSlots: { customRender: "couNo" }
        },
        {
          title: "下单时间",
          dataIndex: "createTime",
          key: "createTime",
          width: 200,
          // scopedSlots: { customRender: "couNo" }
        },
        {
          title: "商品名称",
          dataIndex: "businessName",
          key: "businessName",
          // scopedSlots: { customRender: "price" }
        },
        {
          title: "用户手机号",
          dataIndex: "userName",
          key: "userName",
          // scopedSlots: { customRender: "userName" }
        },
        {
          title: "课程定价",
          dataIndex: "coursePrice",
          key: "coursePrice",
          // scopedSlots: { customRender: "coursePrice" }
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" },
        }
      ],
      orderStatusMap: {},
      orderStatusArr: [
          {
              key: 0,
              value: '全部'
          }
      ],
      status: 0,
      couNo: "",
      userName: "",
    };
  },
  methods: {
    moment,
    handleOrderStatusArr() {
        const dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
        dictionaryObj.orderStatus.forEach(item => {
            this.orderStatusArr.push(item);
            this.orderStatusMap[item.key] = item.value;
        });
    },
    // 获取优惠券列表的数据
    getCouponUsedListFn() {
      this.tableLoading = true;
      CouponUsedDetailApi({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        couNo: this.couNo,
        status: this.status + '',
        userName: this.userName
      })
        .then(({ code, data }) => {
          if (code == 200) {
            this.tableLoading = false;
            this.tableData = data.data;
            this.count = data.count;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 订单状态改变的回调
    orderStatusChange(value) {
      this.pageNo = 1;
      this.status = value;
      this.getCouponUsedListFn();
    },
    // 搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.getCouponUsedListFn();
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getCouponUsedListFn();
    },
  },
};
</script>